<template>
   <div>
      <load-screen-component :is-loading="isLoading"></load-screen-component>
      <page-header :btnNew="'ACTIONS.NEW'" pageTitle="PANEL.PLAN.TITLE" @newType="setViewModal('', 'create')"
      />
      <div v-if="items && !items.validations" class="card mw-100">
         <div class="row mw-100">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
               <div class="font-weight-bolder mt-2 ml-3 text-left">
                  {{ this.t('PANEL.PLAN.PLAN_NAME') }}
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="margin-left: -1rem">
               <div class="font-weight-bolder mt-2 text-left">
                  {{ this.t('PANEL.PLAN.MAX_SIZE_DRIVE') }}
               </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" style="margin-left: -1rem">
               <div class="font-weight-bolder mt-2 text-left">
                  {{ this.t('PANEL.PLAN.MAX_LOTS_PERMITED') }}
               </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
               <div class="font-weight-bolder mt-2 text-left">
                  {{ this.t('PANEL.PLAN.WHATSAPP_NOTIFICATIONS') }}
               </div>
            </div>
         </div>
         <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
            <div class="row mw-100 space-between mt-2">
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                  <div class="list-primary hide-text">
                     {{ iten.name }}
                  </div>
               </div>
               <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                  <div class="list-primary hide-text text-left">
                     {{ iten.size_store_drive ? iten.size_store_drive :'0.0' }} GB
                  </div>
               </div>
               <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                  <div class="list-primary hide-text text-left">
                     {{ iten.max_lots_permitted }}
                  </div>
               </div>
               <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 h-100 w-100">
                  <div class="list-primary hide-text h-100 w-100">
                     {{ iten.whatsapp_notifications  }}
                     <div class="d-flex flex-column justify-content-start h-100">
                        <div class="text-right text-justify" style="margin-top: -1rem">
                           <a type="button" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false"
                              style="font-size: 20px" id="listDropdown">
                              <i class="icon-more_vert" title="Opções"></i>
                           </a>
                           <div class="dropdown-menu dropdown-menu-right dropdown-black">
                              <div class="dropdown-item pointer text-white"
                                   data-toggle="modal" data-target="#customModalTwo"
                                   @click="setViewModal(iten.id, 'view')">
                                 <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                              </div>
                              <div class="dropdown-item pointer text-white"
                                   data-toggle="modal" data-target="#customModalTwo"
                                   @click="setViewModal(iten.id, 'edit')">
                                 <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card" v-if="items && items.validations">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('PANEL.PLAN.NOT_FOUND') }}</h5>
            </div>
         </div>
      </div>
      <div class="row gutters">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="d-flex flex-row">
               <router-link to="/painel-administrativo">
                  <button type="button" class="btn btn-secondary mr-2">{{ t('ACTIONS.BACK') }}</button>
               </router-link>
            </div>
         </div>
      </div>
      <modal :titleModal="modalTitle" :modalLarge="true" @close="index()">
         <template v-slot:title>{{ t(modalTitle) }}</template>
         <create-component v-if="modalChosen === 1" @created="index()"></create-component>
         <edit-component v-if="modalChosen === 0" :planIndex="plan"
                         @edited="index()"></edit-component>
         <view-component v-if="modalChosen === 2" :planIndex="plan"></view-component>
      </modal>
      <pagination-component v-if="items && items.data && items.data[0]" :items="items"
                            :to="'/planos'"
                            @changePage="index(filter, $event), this.page=$event"></pagination-component>
   </div>
</template>

<script>
import PageHeader from "@/components/layouts/pageHeader";
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import LoadScreenComponent from "@/components/layouts/loadScreenComponent";
import PaginationComponent from "../../components/layouts/PaginationComponent";
import {mapState} from "vuex";
import Modal from "@/components/modal";
import CreateComponent from "@/pages/plan/createComponent";
import EditComponent from "@/pages/plan/editComponent";
import ViewComponent from "@/pages/plan/viewComponent";
import Plan from "@/services/Plan";
import jwt_decode from 'jwt-decode';

export default {
   name: "indexComponent.vue",
   components: {
      PageHeader,
      LoadScreenComponent,
      Modal,
      CreateComponent,
      EditComponent,
      PaginationComponent,
      ViewComponent,
   },
   data() {
      return {
         items: null,
         modalTitle: '',
         modalChosen: null,
         plan: null
      }
   },
   computed: {
      ...mapState({
         isLoading: 'isLoading'
      })
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   mounted() {
       if (!this.hasPermissionShowPanel()) {
           this.$router.push({name: 'Home'});
       }
      this.index();
   },
   methods: {
       hasPermissionShowPanel() {
           let token = jwt_decode(localStorage.getItem('access_token'));
           return token.email.includes('@r2soft.com.br');
       },
      formatSize(fileSize) {
         if (!fileSize) return '0.0';
         if (fileSize < 1000) return `${fileSize} Bytes`;
         if (fileSize > 1000 && fileSize < 1000000) return `${(fileSize / 1000).toFixed()} KB`;
         if (fileSize >= 1000000 && fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(2)} MB`;
         if (fileSize >= 1000000000) return `${(fileSize / 1000000000).toFixed(2)} GB`;
      },
      async show(id) {
         this.$store.commit('changeLoading', true);
         this.plan = {};
         await Plan.show(id).then((resp) => {
            this.plan = resp.data;
            this.$store.commit('changeLoading', false);
         }).catch(() => {
            this.$store.commit('changeLoading', false);
         })
      },
      async setViewModal(id, type) {
         switch (type) {
            case 'edit':
               this.$store.commit('changeLoading', true);
               await this.show(id);
               this.modalTitle = 'PANEL.PLAN.PLAN_EDIT';
               this.modalChosen = 0;
               this.$store.commit('changeLoading', false);
               break;
            case 'view':
               this.$store.commit('changeLoading', true);
               await this.show(id).then(() => this.modalChosen = 2);
               this.modalTitle = 'PANEL.PLAN.PLAN_VIEW';
               this.$store.commit('changeLoading', false);
               break;
            case 'create':
               this.modalTitle = 'PANEL.PLAN.PLAN_CREATE';
               this.modalChosen = 1;
               break;
         }
      },
      index(filter = null, page = 1) {
         this.modalTitle = '';
         this.modalChosen = null,
             this.$store.commit('changeLoading', true);
         Plan.index(filter, page).then((resp) => {
            this.items = resp.data;
            this.$store.commit('changeLoading', false)
         }).catch(() => this.$store.commit('changeLoading', false));
      }
   }
}
</script>

<style scoped>

</style>